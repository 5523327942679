import React from "react";
import classes from './Vacancy.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const Vacancy = ({ data }) => {
    const { t } = useTranslation();
    const { banner, reqs, resp, overview, apply, compensation } = data;
    return (
        <section className="mt container font-16">
            <div className={classes.vacancy}>
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h2 className={`${classes.title} font-40`}>
                            {t(banner.title)}
                        </h2>
                        {banner.desc &&
                            <h4 className={`${classes.desc} font-20`}>
                                {t(banner.desc)}        
                            </h4>
                        }
                        <p className={classes.text}>
                            {t(banner.text)}
                        </p>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn_apply_now')}
                            </Button>
                        </div>
                    </div>
                    <img className={classes.image} src={banner.image} alt=''/>
                </div>
                <div className={classes.reqRes}>
                    <div className={classes.col}>
                        <h4 className={`${classes.title} font-20`}>
                            {t(reqs.title)}
                        </h4>
                        <ul className={classes.list}>
                            {reqs.list.map((li, index) =>
                                <li key={index}>
                                    {t(li)}
                                </li>
                            )}
                        </ul>
                        {reqs?.text &&
                            <p className={classes.text}>
                                {t(reqs.text)}
                            </p>
                        }
                    </div>
                    <div className={classes.col}>
                        <h4 className={`${classes.title} font-20`}>
                            {t(resp.title)}
                        </h4>
                        <ul className={classes.list}>
                            {resp.list.map((li, index) =>
                                <li key={index}>
                                    {t(li)}
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className={classes.overview}>
                    <div className={classes.col}>
                        <div className={classes.block}>
                            <h2 className={`${classes.title} font-32`}>
                                {t(overview.title)}
                            </h2>
                            <div className={`${classes.text} txt-block`}>
                                {overview.text.map((text, index) =>
                                    <p key={index}>
                                        {t(text)}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.block}>
                            <h4 className={`${classes.title} font-32`}>
                                {t(apply.title)}
                            </h4>
                            <p className={classes.text}>
                                {t(apply.text)}
                            </p>
                        </div>
                        <div className={classes.block}>
                            <h4 className={`${classes.title} font-32`}>
                                {t(compensation.title)}
                            </h4>
                            <ul className={classes.list}>
                                {compensation.list.map((li, index) =>
                                    <li key={index}>
                                        {t(li)}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Vacancy;
