import React from "react";
import classes from './Loader.module.scss';
import logo from '../../../assets/img/logo.svg';

const Loader = ({ isLoading }) => {
    return (
        <div className={`${classes.loader} ${!isLoading && classes.hide}`}>
            <img className={classes.logo} src={logo} alt=''/>
        </div>
    );
};

export default Loader;
