import { createContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./components/GlobalComponents/Router/AppRouter";
import './App.scss';
import { images } from "./utils/imagesToLoad";
import $ from 'jquery';
import Loader from "./components/GlobalComponents/Loader/Loader";
import Form from "./components/GlobalComponents/Form/Form";

export const PopupContext = createContext(null);

function App() { 
  const [ popupActive, setPopupActive ] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };
  useEffect(() => {
    if (isLoading) {
      $('body').addClass("ovf-hidden");
    }
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => console.log("Failed to load images", err));
    // eslint-disable-next-line  
  }, []);
  useEffect(() => {
    if (!isLoading) {
      $('body').removeClass("ovf-hidden");
    }
  }, [isLoading]); 

  return (
    <BrowserRouter>
      <PopupContext.Provider value={{ active: popupActive, setActive: setPopupActive }}>
        <Loader isLoading={isLoading} />
        <Router />
        <Form active={popupActive} setActive={setPopupActive} />
      </PopupContext.Provider>
    </BrowserRouter>
  );
}

export default App;
