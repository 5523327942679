import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";
import icon_phone from '../../../assets/img/Footer/icon_phone.svg';
import icon_mail from '../../../assets/img/Footer/icon_mail.svg';
import icon_location from '../../../assets/img/Footer/icon_location.svg';
import image from '../../../assets/img/Footer/image.png';

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <footer className="mt container">
      <div className={classes.footer}>
        <div className={classes.content}>
          <h2 className={`${classes.title} font-48`}>
            {t('footer_title')}
          </h2>
          <h3 className={`${classes.subtitle} font-32`}>
            {t('footer_subtitle')}
          </h3>
          <div className={classes.bottom}>
            <a href="tel:+441224076105" className={classes.link}>
              <img className={classes.icon} src={icon_phone} alt='' />
              +441224076105
            </a>
            <a href={`mailto:support@${hostname}`} className={classes.link}>
              <img className={classes.icon} src={icon_mail} alt='' />
              support@{hostname}
            </a>
            <div className={classes.link}>
              <img className={classes.icon} src={icon_location} alt='' />
              50 street 148, Phnom Penh 14206, Cambodia
            </div>
          </div>
        </div>
        <img className={classes.image} src={image} alt='' />
      </div>
    </footer>
  );
};

export default Footer;
